import React from 'react';
import ReactSoundcloud from 'react-soundcloud-embed';

const SoundCloudEmbed = ({ node }) => {
  if (!node.url) {
    return null;
  }
  return <ReactSoundcloud url={node.url} />;
}

export default SoundCloudEmbed;