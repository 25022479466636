import React from 'react';
import ReactPlayer from 'react-player';
import Container from './container';

import * as styles from './vimeo-player.module.css';

const VideoEmbed = ({ node, contained = true }) => {
  if (!node.url) {
    return null
  }

  if (contained) {
    return (
      <Container>
        <div className={styles.root}>
          <ReactPlayer 
            className={styles.player}
            url={node.url} 
            width="100%"
            height="100%" 
            controls={true}
          />
        </div>
      </Container>
    );
  } else {
    return (
      <div className={styles.root}>
        <ReactPlayer 
          className={styles.player}
          url={node.url} 
          width="100%"
          height="100%" 
          controls={true}
        />
      </div>
    );
  }
};

export default VideoEmbed;