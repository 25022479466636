import VideoEmbed from "./video-embed";
import SoundCloudEmbed from "./soundcloud-embed";
import { Figure, ArticleFigure } from "./figure";

const serializers = {
  types: {
    figure: Figure,
    videoEmbed: VideoEmbed,
    articleFigure: ArticleFigure,
    soundcloud: SoundCloudEmbed
  }
};

export default serializers;
